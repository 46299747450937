.root {
    max-width: 100%;
    display: flex;
    align-items: center;
}

.inDrawer {
    justify-content: space-between;
    width: 100%;
}

.cluster {
    display: flex;
    align-items: center;
}

.scrollContainer {
    padding: 0;
    display: flex;
}

.sizeContainer {
    position: relative;
    z-index: 2;
}

/* New price button styles mean turning off blend mode for now.
I'm leaving the code here, though, as it can be tricky to work out, especially with complicated stacking contexts */
.priceContainer {
    display: flex;
    align-items: center;
    /* mix-blend-mode: difference; */
    /* color: var(--color-brand-white); */
    z-index: 2;
}

.blur {
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-size-two);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(35px);
    -webkit-backdrop-filter: blur(35px);
}

.spaceBetween {
    margin-inline-end: var(--spacing-size-two);
}

.compareAtPrice {
    color: var(--color-brand-titanium);
    margin-right: var(--spacing-size-two);
    text-decoration: line-through;
    text-wrap: nowrap;
    white-space: nowrap;
}

.price {
    text-wrap: nowrap;
    white-space: nowrap;
}

.cartButton {
    width: 80px;
}

.gradientMask {
    position: relative;
    display: flex;
}

.gradientMask:before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    width: 24px;
    height: 100%;
    background: linear-gradient(
        -90deg,
        var(--color-brand-white) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    opacity: 0;
    pointer-events: none;
}

.gradientMask.active:before {
    opacity: 1;
}

.wishlist {
    min-width: 40px;
    height: 40px;
    border-radius: var(--border-radius-lg);
    background-color: var(--color-brand-aluminium);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 3;
}

@media screen and (max-width: 767px) {
    .root {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-size-three);
        align-items: unset;
        padding: var(--spacing-size-five) var(--spacing-size-five)
            var(--spacing-size-five) 0;
    }

    .scrollContainer {
        overflow-x: auto;
        white-space: nowrap;
        position: relative;
        display: flex;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Edge */
    }

    .scrollContainer::-webkit-scrollbar {
        display: none;
    }

    .spacer {
        padding-inline-start: var(--spacing-size-five);
    }
}

.disabled {
    cursor: not-allowed;
}
